import {
  AddDiscountEvent,
  AddToCartEvent,
  EventObject,
  RemoveDiscountEvent,
  RemoveFromCartEvent,
  SelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { getCurrencyCode } from '@moonpig/web-shared-utils'

export type GAEvent = {
  event: string
  content_data: {
    content_type: string
    item_id?: string
  }
  event_data: {
    action: string
    category: string
    label?: string
    non_interaction: boolean
    value?: unknown
  }
  user_data?: {
    ct_id?: string
    is_logged_in: boolean
    lifetime_order_count: number | undefined
    method: string
    mp_id?: string
  }
  ecommerce?: unknown
  discount_data?: unknown
  error_data?: ErrorData
}

export enum EventType {
  SelectContent = 'select_content',
  Impression = 'impression',
  Error = 'error',
}

export type ErrorData = {
  id: string
  message: string
}

type BaseAccountGAEventInput = {
  event: 'select_content'
  action: string
  ct_id?: string
  label: string
  error_data?: ErrorData
  category?: string
  content_type?: string
}

const BaseAccountGAEvent = (
  input: BaseAccountGAEventInput,
): SelectContentEvent => {
  const { event, action, label, category, content_type } = input

  return {
    event,
    content_data: {
      content_type: content_type || `account | ${action}`,
    },
    event_data: {
      action,
      category: category || 'account',
      non_interaction: true,
      label,
    },
  }
}

const eventLabel = `membership | plus`
const ecommerceItemCategory = 'membership'
const ecommerceItemId = `membership-plus`
const ecommerceItemName = 'plus'

export const trackImpression = () => {
  trackGAEvent({
    event: 'impression',
    content_data: {
      content_type: `promotion | view promotion | ${eventLabel}`,
      item_id: ecommerceItemId,
    },
    event_data: {
      action: 'view promotion',
      category: 'promotion',
      label: eventLabel,
      non_interaction: true,
      value: 1,
    },
  })
}

export const trackSelectContent = (eventDataAction: string) => {
  trackGAEvent({
    event: 'select_content',
    content_data: {
      content_type: `promotion | ${eventDataAction} | ${eventLabel}`,
      item_id: ecommerceItemId,
    },
    error_data: undefined,
    event_data: {
      action: eventDataAction,
      category: 'promotion',
      label: eventLabel,
      non_interaction: true,
      value: undefined,
    },
  })
}

type Price = {
  centAmount: number
  currencyCode: string
}

type TrackMembershipEventInput = {
  price: Price
  brand: string
  basketId?: string
  basketSize?: number
}

export const trackViewItem = ({ price, brand }: TrackMembershipEventInput) => {
  const membershipPrice = price.centAmount / 100
  const amount = membershipPrice.toFixed(2)
  trackGAEvent({
    event: 'view_item',
    ecommerce: {
      currency: getCurrencyCode(price.currencyCode.toUpperCase()),
      items: [
        {
          index: 1,
          quantity: 1,
          item_brand: brand.toLowerCase(),
          item_id: ecommerceItemId,
          item_name: ecommerceItemName,
          price: parseFloat(amount),
        },
      ],
      value: parseFloat(amount),
    },
    event_data: {
      action: 'view item',
      category: 'product action',
      label: eventLabel,
      non_interaction: true,
      value: Math.round(membershipPrice),
    },
  })
}

type CartActionArgs = {
  eventName: 'add_to_cart' | 'remove_from_cart'
  eventDataAction: string
} & TrackMembershipEventInput

const cartAction = ({
  eventName,
  eventDataAction,
  price,
  brand,
  basketId,
  basketSize,
}: CartActionArgs): AddToCartEvent | RemoveFromCartEvent => {
  const membershipPrice = price.centAmount / 100
  const amount = membershipPrice.toFixed(2)
  return {
    event: eventName,
    basket_data: {
      id: basketId as string,
      size: basketSize as number,
      update_initiator: eventName === 'add_to_cart' ? 'add to cart' : 'delete',
    },
    ecommerce: {
      currency: getCurrencyCode(price.currencyCode.toUpperCase()),
      items: [
        {
          index: 1,
          item_brand: brand.toLowerCase(),
          item_category: ecommerceItemCategory,
          item_id: ecommerceItemId,
          item_name: ecommerceItemName,
          price: parseFloat(amount),
          quantity: 1,
        },
      ],
      value: parseFloat(amount),
    },
    event_data: {
      action: eventDataAction,
      category: 'product action',
      label: eventLabel,
      non_interaction: true,
      value: Math.round(membershipPrice),
    },
  }
}

export const trackAddToCart = ({
  price,
  brand,
  basketId,
  basketSize,
}: TrackMembershipEventInput) =>
  trackGAEvent(
    cartAction({
      price,
      brand,
      eventName: 'add_to_cart',
      eventDataAction: 'add to basket',
      basketId,
      basketSize,
    }),
  )

export const trackRemoveFromCart = ({
  price,
  brand,
  basketId,
  basketSize,
}: TrackMembershipEventInput) =>
  trackGAEvent(
    cartAction({
      price,
      brand,
      eventName: 'remove_from_cart',
      eventDataAction: 'remove from basket',
      basketId,
      basketSize,
    }),
  )

const discountEvent = ({
  eventName,
  eventDataAction,
  price,
}: {
  eventName: 'add_discount' | 'remove_discount'
  eventDataAction: string
  price: Price
}): AddDiscountEvent | RemoveDiscountEvent => {
  const membershipPrice = price.centAmount / 100
  const amount = membershipPrice.toFixed(2)
  return {
    event: eventName,
    discount_data: {
      name: eventLabel,
      type: 'promotion',
      value: parseFloat(amount),
      currency: getCurrencyCode(price.currencyCode.toUpperCase()),
    },
    event_data: {
      action: eventDataAction,
      category: 'promotion',
      label: eventLabel,
      non_interaction: true,
      value: Math.round(membershipPrice),
    },
  }
}

export const trackRemoveDiscount = ({ price }: { price: Price }) =>
  trackGAEvent(
    discountEvent({
      price,
      eventName: 'remove_discount',
      eventDataAction: 'remove discount',
    }),
  )

export const trackAddDiscount = ({ price }: { price: Price }) =>
  trackGAEvent(
    discountEvent({
      price,
      eventName: 'add_discount',
      eventDataAction: 'add discount',
    }),
  )

export const trackDiscountError = ({
  errorCode,
  message,
  price,
}: {
  errorCode: number
  message: string
  price: {
    centAmount: number
    currencyCode: string
  }
}) => {
  const membershipPrice = price.centAmount / 100
  const amount = membershipPrice.toFixed(2)

  trackGAEvent({
    event: 'discount_error',
    discount_data: {
      name: eventLabel,
      type: 'promotion',
      value: parseFloat(amount),
      currency: getCurrencyCode(price.currencyCode.toUpperCase()),
    },
    error_data: {
      id: errorCode,
      message,
    },
    event_data: {
      action: 'discount error',
      category: 'promotion',
      label: eventLabel,
    },
  })
}

export const trackAccountEvent = (
  event: (ct_id: string) => EventObject,
  ct_id: string,
) => {
  const instance = event(ct_id)
  trackGAEvent(instance)
}

export const MoonpigPlusUpdateMarketingPreferencesOptInGAEvent = (
  ct_id: string,
): SelectContentEvent => {
  return BaseAccountGAEvent({
    event: EventType.SelectContent,
    action: 'plus membership update marketing preferences - opt-in',
    ct_id,
    label: 'opt in',
  })
}

export const MoonpigPlusUpdateMarketingPreferencesOptOutGAEvent = (
  ct_id: string,
) => {
  return BaseAccountGAEvent({
    event: EventType.SelectContent,
    action: 'plus membership update marketing preferences - opt-out',
    ct_id,
    label: 'opt out',
  })
}
